<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.15625 13.0625C10.4182 13.0625 13.0625 10.4182 13.0625 7.15625C13.0625 3.89432 10.4182 1.25 7.15625 1.25C3.89432 1.25 1.25 3.89432 1.25 7.15625C1.25 10.4182 3.89432 13.0625 7.15625 13.0625Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.3328 11.3328L14.75 14.75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
